import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default function MyEditor() {
  const [editorContent, setEditorContent] = useState('');

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(editorContent); // Agora você tem o conteúdo do editor disponível aqui
  };

  return (
    <div className="main main-app p-3 p-lg-4 d-flex flex-column gap-3">
      <form onSubmit={handleSubmit}>
        <Editor
          id="editor"
          name="editor"
          apiKey="q720jt59xb1dil3g4csxi4kvmlpsscwd448ty95bzovcu8kj"
          init={{
            plugins:
              'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
            toolbar:
              'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
          }}
          initialValue="Welcome to TinyMCE!"
          onEditorChange={handleEditorChange}
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}
