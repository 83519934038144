import Api from "../endpoint";

const AuthService = {
  login: (data) => Api.post("/login", data),
  logout: (data) => Api.post("/logout", data),
  register: (data) => Api.post("/user/register", data),
  getProfile: (data) => Api.get("/user/profile", data),
  deleteUser: (data) => Api.delete("/user/delete", { params: data }),
  updateProfile: (data) => Api.put("/user/profile", data),
  updatePassword: (data) => Api.put("/profile/password", data),
  updateAvatar: (data) => Api.put("/profile/avatar", data),
  selfRegister: data => Api.post("/auth/register", data),
  check: () => Api.get("/auth/check"),
  sendVerificationCode: (data) => Api.post("/auth/sendEmailRestorePassword", data),
  resetPassword: (data) => Api.put("/auth/resetPassword", data)
}

export default AuthService;