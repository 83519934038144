import React, { useContext, useEffect } from 'react';
import { OsContext } from '../../../context/OsContext';
import { useStore } from '../../../store/store';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { OsCardComponent } from '../../../components/Os/Cards';
import { OsListComponent } from '../../../components/Os/List';
import { ModalCriaOs } from '../../../components/ModalCriaOs';
import { ScreenContext } from '../../../context/ScreenContext';

export const PageListOsFromCompanies = () => {
  const { id } = useParams();
  const { screenSize } = useContext(ScreenContext);
  const { listOsFromCompany, osList } = useStore((store) => {
    return {
      companies: store.companies,
      listOsFromCompany: store.listOsFromCompany,
      osList: store.osList,
    };
  });

  useEffect(() => {
    listOsFromCompany(id);
  }, []);
  return (
    <React.Fragment>
      <div className="main main-app p-3 p-lg-4">
        <div>
          <Card className="mb-3">
            <Card.Body
              className={`d-flex align-items-center justify-content-between`}
            >
              <div>
                <h4 className="main-title mb-0">Lista de Atividades</h4>
              </div>
            </Card.Body>
          </Card>
        </div>

        {screenSize.width < 1200 ? (
          <OsCardComponent osList={osList} />
        ) : (
          <Card className="card-one">
            <Card.Body>
              {' '}
              <OsListComponent osList={osList} />{' '}
            </Card.Body>
          </Card>
        )}
      </div>
    </React.Fragment>
  );
};
