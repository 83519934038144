import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CiClock1, CiUser } from 'react-icons/ci';
import './Cards.css';
import { ScreenContext } from '../../context/ScreenContext';
import { FaRegUser } from 'react-icons/fa';
import { GoClock } from 'react-icons/go';

export const OsCardComponent = ({ osList }) => {
  const { screenSize } = useContext(ScreenContext);
  const [shouldAnimate, setShouldAnimate] = useState([]);

  useEffect(() => {
    const checkWidths = () => {
      const newShouldAnimate = osList.map((_, index) => {
        const element = document.getElementById(`locale-name-${index}`);
        if (element) {
          return element.scrollWidth > element.clientWidth;
        }
        return false;
      });
      setShouldAnimate(newShouldAnimate);
    };

    checkWidths();
    window.addEventListener('resize', checkWidths);
    return () => window.removeEventListener('resize', checkWidths);
  }, [osList]);

  return (
    <div className="d-flex flex-column gap-1">
      {osList.map((os, index) => (
        <Card className="card-one rounded-0" key={index}>
          <Link to={`/os/${os.id}`}>
            <Card.Body className={screenSize.width > 1100 ? 'px-5' : 'px-3'}>
              <div className="d-flex flex-row justify-content-between">
                <Card.Title
                  style={{
                    fontSize: '1rem',
                  }}
                  id={`locale-name-${index}`}
                  className={`w-100 d-flex flex-row align-items-center justify-content-between ${
                    shouldAnimate[index] ? 'marquee-animate' : ''
                  }`}
                >
                  <strong>{os.locale_name}</strong>
                </Card.Title>

                <div
                  style={{
                    marginLeft: '5px',
                  }}
                  className="d-flex flex-row gap-1 align-items-center"
                >
                  <span>
                    {new Date(os.start_date).toLocaleDateString('pt-BR')}
                  </span>
                  <GoClock />
                </div>
              </div>
              <div className="">
                <div className={'text-muted'}>
                  {os.locale_street} Nº {os.locale_number},{' '}
                  {os.locale_neighborhood}, <br />
                  {os.locale_city}/{os.locale_state}, {os.locale_complement}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <strong
                  className={`rounded p-1 text-center mt-2`}
                  style={{
                    color: os.os_status_text_color,
                    backgroundColor: os.status_color,
                  }}
                >
                  (OS-{os.os_code})
                </strong>
                <strong className="d-flex flex-row align-items-center gap-1">
                  <span>
                    {os.responsible_name?.split(' ')[0]}{' '}
                    {os.responsible_name?.split(' ')[1] == 'da' ||
                    os.responsible_name?.split(' ')[1] == 'de'
                      ? os.responsible_name?.split(' ')[1] +
                        ' ' +
                        os.responsible_name?.split(' ')[2]
                      : os.responsible_name?.split(' ')[1]}{' '}
                  </span>
                  <FaRegUser />
                </strong>
              </div>
            </Card.Body>
          </Link>
        </Card>
      ))}
    </div>
  );
};
