import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import AppMenu from "../data/Menu";
import logo from "../assets/svg/loco_header_theme_white.svg";
import { AuthContext } from "../context/AuthContext";

const Sidebar = () => {
    

    const { user, logout } = useContext(AuthContext)
    const scrollBarRef = useRef(null);

    const toggleFooterMenu = (e) => {
        e.preventDefault();
        let parent = e.target.closest(".sidebar");
        parent.classList.toggle("footer-menu-show");
    };

    useEffect(() => {
        const handleSidebarSkin = () => {
            let skinMode = localStorage.getItem("sidebar-skin");
            let HTMLTag = document.querySelector("html");

            if (skinMode) {
                HTMLTag.setAttribute("data-sidebar", skinMode);
            }
        };

        window.addEventListener("load", handleSidebarSkin);

        return () => {
            window.removeEventListener("load", handleSidebarSkin);
        };
    }, []);


    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <Link to="/" className="d-flex align-items-end sidebar-logo">
                    <img src={logo} width={50} alt="" />
                    <span className="mb-2">estec</span>
                </Link>
            </div>
            <PerfectScrollbar className="sidebar-body" ref={scrollBarRef}>
                <SidebarMenu onUpdateSize={() => scrollBarRef.current.updateScroll()} />
            </PerfectScrollbar>
            <div className="sidebar-footer">
                <div className="sidebar-footer-top">
                    <div className="sidebar-footer-thumb">
                        <img src={user.profile_image_path ? "https://api-gestec.qtsys.com.br/" + user.profile_image_path : userAvatar} alt="" />
                    </div>
                    <div className="sidebar-footer-body">
                        <h6><Link to="../pages/profile.html">{user.name}</Link></h6>
                    </div>
                    <Link onClick={toggleFooterMenu} to="" className="dropdown-link"><i className="ri-arrow-down-s-line"></i></Link>
                </div>
                <div className="sidebar-footer-menu">
                    <nav className="nav">
                        <Link to="/perfil"><i className="ri-edit-2-line"></i> Editar</Link>
                        <Link onClick={logout}><i className="ri-logout-box-r-line"></i> Log Out</Link>
                    </nav>
                    <hr />
                    <nav className="nav">
                        <Link to=""></Link>
                        <Link to=""></Link>
                        <Link to=""></Link>
                        <Link></Link>
                    </nav>
                </div>
            </div>
        </div>
    );
};

const SidebarMenu = ({ onUpdateSize }) => {
    const appMenu = AppMenu();
    const populateMenu = (m) => {
        return m.map((item, key) => {
            let sm = item.submenu ? item.submenu.map((sm, key) => (
                <NavLink to={sm.link} className="nav-sub-link" key={key}>{sm.label}</NavLink>
            )) : null;

            return (
                <li key={key} className="nav-item">
                    {!sm ? (
                        <NavLink to={item.link} className="nav-link"><i className={item.icon}></i> <span>{item.label}</span></NavLink>
                    ) : (
                        <div onClick={(e) => toggleSubMenu(e, onUpdateSize)} className="nav-link has-sub"><i className={item.icon}></i> <span>{item.label}</span></div>
                    )}
                    {item.submenu && <nav className="nav nav-sub">{sm}</nav>}
                </li>
            );
        });
    };

    const toggleSubMenu = (e, onUpdateSize) => {
        e.preventDefault();
        let parent = e.target.closest('.nav-item');
        let node = parent.parentNode.firstChild;

        while (node) {
            if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
                node.classList.remove('show');
            node = node.nextElementSibling || node.nextSibling;
        }

        parent.classList.toggle('show');
        onUpdateSize();
    };

    return (
        <React.Fragment>
            <div className="nav-group show">
                <div className="nav-label" onClick={(e) => toggleMenu(e, onUpdateSize)}>Menu</div>
                <ul className="nav nav-sidebar">
                    {populateMenu(appMenu)}
                </ul>
            </div>
        </React.Fragment>
    );
};

const toggleMenu = (e, onUpdateSize) => {
    e.preventDefault();
    let parent = e.target.closest('.nav-group');
    parent.classList.toggle('show');
    onUpdateSize();
};

export default Sidebar;