import React from 'react';
import {
  Card,
  Table,
  Button,
  Badge,
  Dropdown,
  DropdownItem,
} from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { ModalEditaOs } from '../ModalEditaOs/EditaOs';
import { Link } from 'react-router-dom';
import { AlterarStatusOs } from '../ModalEditaOs/AlteraStatusOs';

export const OsListComponent = ({ osList }) => {
  return (
    <Table bordered hover responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Código da OS</th>
          <th scope="col">Local</th>
          <th scope="col">Data de Início</th>
          <th scope="col">Status</th>
          <th scope="col">Valor</th>
          <th scope="col" style={{ width: '280px' }}>
            Ações
          </th>{' '}
          {/* Ajuste a largura conforme necessário */}
        </tr>
      </thead>
      <tbody>
        {osList?.map((os, index) => (
          <tr key={index}>
            <td>
              <strong>{os.os_code}</strong>
            </td>
            <td>{os.locale_name}</td>
            <td>{new Date(os.start_date).toLocaleDateString()}</td>
            <td>
              <span
                className="px-2 py-1 rounded"
                style={{
                  backgroundColor: os.status_color,
                  color: os.os_status_text_color,
                }}
              >
                {os.status_name}
              </span>
            </td>
            <td>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(Number(os.os_value))}
            </td>
            <td className="d-flex py-1 gap-2">
              {' '}
              {/* Ajuste a largura conforme necessário */}
              <Link to={`/os/${os.id}`}>
                <Button>Visualizar</Button>
              </Link>
              <AlterarStatusOs os={os} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
