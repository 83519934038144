import React, { useEffect, useState } from 'react';
import { Card, ListGroup, Modal } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import { Link } from 'react-router-dom';
import { OsCardComponent } from '../../../components/Os/Cards';

export const ListaDeOsPorEmpresas = () => {
  const { companies, listOsFromCompany, osList } = useStore((store) => {
    return {
      companies: store.companies,
      listOsFromCompany: store.listOsFromCompany,
      osList: store.osList,
    };
  });

  const [modalListaOsShow, setModalListaOsShow] = useState(false);

  const handleOpenModalListaOs = (id) => () => {
    setModalListaOsShow(true);
    listOsFromCompany(id);
  };

  return (
    <React.Fragment>
      <div className="main main-app p-3 p-lg-4">
        <div>
          <Card className="mb-3">
            <Card.Body
              className={`d-flex align-items-center justify-content-between`}
            >
              <div>
                <h4 className="main-title mb-0">Lista de Ordem de Serviços</h4>
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <ListGroup>
                {companies?.map((company, index) => (
                  <ListGroup.Item
                    as={Link}
                    className="border-0"
                    action
                    to={`/os/lista/empresa/${company.id}`}
                    // onClick={handleOpenModalListaOs(company.id)}
                    key={index}
                  >
                    <h5>{company.company_name}</h5>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
      </div>
      <Modal
        size="xl"
        show={modalListaOsShow}
        onHide={() => setModalListaOsShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Lista de Os</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <OsCardComponent osList={osList} />
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
