import React, { useState, useEffect, useContext } from "react";
import { Button, Card, Col, Container, Form, Nav, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import pageSvg from "../assets/svg/forgot_password.svg";
import { AuthContext } from "../context/AuthContext";

export default function ForgotPassword() {
  const { sendVerificationCode } = useContext(AuthContext);
  const skin = localStorage.getItem('skin-mode');




  const handleReset = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target);
    const response = await sendVerificationCode({ email: formData.get('email') })
  }

  document.body.classList.remove("sidebar-show");

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link to="/" className="header-logo mb-5">Gestec</Link>
        </Container>
      </div>

      <div className="content">
        <Container className="w-100">
          <Card className="card-auth">
            <Card.Body className="text-center">
              <div className="mb-5">
                <object type="image/svg+xml" data={pageSvg} className="w-50" aria-label="svg image"></object>
              </div>
              <Card.Title>Recuperação de senha de acesso</Card.Title>
              <Card.Text className="mb-5">Digite o seu e-mail, enviaremos um código para redefinição de senha.</Card.Text>
              <form className="g-3 p-0" onSubmit={handleReset}>
                <Row className="g-2 p-0 d-flex align-items-center justify-content-center">
                  <Col sm="12" className="g-3 p-0">
                    <Form.Control
                      className="text-center w-100"
                      required type="email"
                      name="email"
                      id="email"
                      placeholder="Email" />
                  </Col>

                  <Col sm="5"><Button type="submit" className="" >Enviar Código</Button></Col>
                </Row>
              </form>
              <Row className="p-5">
                <Link to="/">Voltar para tela de Login</Link>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
}