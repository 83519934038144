import { useContext, useEffect, useState } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { OsContext } from '../../context/OsContext';

const ModalEditaImagem = ({ os, item }) => {
  const [show, setShow] = useState(false);
  const [itemData, setItemData] = useState(item);
  const { listFiles, osFilesUpdate } = useContext(OsContext);
  useEffect(() => {
    setItemData(item);
  }, [item]);
  const handleOpenModal = () => {
    setShow(true);
    console.log(item);
  };

  const handleSetItemData = (e) => {
    const { name, value } = e.target;
    setItemData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdate = async (e) => {
    const payload = {
      file_description: itemData.file_description,
      id: itemData.id,
    };

    const response = await osFilesUpdate(payload);
    if (response) {
      setShow(false);
      listFiles(os);
    }
  };
  return (
    <>
      <Button onClick={handleOpenModal} variant="primary" className="px-3 py-2">
        Visualizar/Editar
      </Button>
      <Modal size="xl" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{itemData.file_description}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column gap-3">
          <Card className="card-one">
            <Card.Body>
              <Form.Label as={'h4'}>Descrição da imagem</Form.Label>
              <Form.Control
                name="file_description"
                id="file_description"
                value={itemData.file_description}
                onChange={(e) => handleSetItemData(e)}
              ></Form.Control>
            </Card.Body>
          </Card>
          <Card className="card-one">
            <Card.Body>
              <Card.Img
                src={`${process.env.REACT_APP_API_URL}${item.file_path}`}
                variant="top"
              />
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalEditaImagem;
