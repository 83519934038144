import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

const InputMoeda = ({ value, onChange, ...props }) => {
  // Estado local para armazenar o valor formatado
  const [valorDisplay, setValorDisplay] = useState('');

  useEffect(() => {
    // Atualiza o valor de exibição quando o valor externo muda
    setValorDisplay(formatarValorParaMoeda(value));
  }, [value]);

  const handleChange = (e) => {
    const valor = e.target.value;
    // Remove caracteres não numéricos para obter apenas o valor numérico
    const valorNumerico = valor.replace(/\D/g, '');
    // Converte para número e divide por 100 para ajustar os centavos
    const valorReal = valorNumerico ? parseFloat(valorNumerico) / 100 : 0;
    // Chama a função onChange externa com o valor numérico real
    onChange(valorReal);
    // Atualiza o valor de exibição com o valor formatado
    setValorDisplay(formatarValorParaMoeda(valorReal));
  };

  const formatarValorParaMoeda = (valor) => {
    // Formata o valor para o formato de moeda brasileira
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  return (
    <Form.Control
      {...props}
      value={valorDisplay}
      onChange={handleChange}
      // Impede que o usuário digite caracteres não numéricos
    />
  );
};

export default InputMoeda;
