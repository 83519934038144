import { useState, useEffect, useContext } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { OsContext } from '../../context/OsContext';
import { realMoeda } from '../../Functions/Mask';
import InputMoeda from '../InputMoeda/InputModea';
import { ScreenContext } from '../../context/ScreenContext';

export const ModalEditaOs = () => {
  const { update, os, getOs } = useContext(OsContext);
  const [osData, setOsData] = useState({});
  const { screenSize } = useContext(ScreenContext);

  useEffect(() => {
    setOsData(os);
  }, [os]);

  const [modalShow, setModalShow] = useState(false);

  const handleOpenModal = () => {
    setModalShow(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.stopPropagation(); // Prevent event from being handled further up in the DOM
    }
  };

  const handleSave = async () => {
    const response = await update(osData);
    if (response) {
      setModalShow(false);
      getOs(osData.id);
    }
  };

  return (
    <>
      <Button
        className={`${
          screenSize.width < 1200 ? `p-3 ` : ''
        } w-100 d-flex align-self-start flex-row gap-2 align-items-center justify-content-center`}
        onClick={handleOpenModal}
      >
        <i class="ri-edit-line"></i> Editar OS
      </Button>
      <Modal
        show={modalShow}
        fullscreen={screenSize.width < 1200}
        size="lg"
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Editar Ordem de Serviço</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Valor da OS</Card.Title>
                <Form.Group className="mb-3">
                  <InputMoeda
                    type="text"
                    name="os_value"
                    id="os_value"
                    value={Number(osData.os_value) || 0} // Certifique-se de que o valor inicial seja numérico
                    onChange={(valor) =>
                      setOsData({ ...osData, os_value: valor })
                    }
                  />
                </Form.Group>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Endereço</Card.Title>
                <Form.Group className="mb-3">
                  <Form.Label>Rua</Form.Label>
                  <Form.Control
                    type="text"
                    name="locale_street"
                    id="locale_street"
                    value={osData.locale_street || ''}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Número</Form.Label>
                  <Form.Control
                    type="text"
                    name="locale_number"
                    value={osData.locale_number || ''}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Complemento</Form.Label>
                  <Form.Control
                    type="text"
                    name="locale_complement"
                    value={osData.locale_complement || ''}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Bairro</Form.Label>
                  <Form.Control
                    type="text"
                    name="locale_neighborhood"
                    value={osData.locale_neighborhood || ''}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Cidade</Form.Label>
                  <Form.Control
                    type="text"
                    name="locale_city"
                    value={osData.locale_city || ''}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Estado</Form.Label>
                  <Form.Control
                    type="text"
                    name="locale_state"
                    value={osData.locale_state || ''}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>CEP</Form.Label>
                  <Form.Control
                    type="text"
                    name="cep"
                    value={osData.cep || ''}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Data de Início</Form.Label>
                  <Form.Control
                    type="date"
                    name="start_date"
                    value={osData.start_date || ''}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Término</Form.Label>
                  <Form.Control
                    type="date"
                    name="end_date"
                    value={osData.end_date || ''}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>
              </Card.Body>
            </Card>

            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Detalhamento da OS</Card.Title>
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    name="os_description"
                    value={osData.os_description || ''}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  ></Form.Control>
                </Form.Group>
              </Card.Body>
            </Card>

            <div className="d-flex justify-content-center gap-3">
              <Button
                style={
                  screenSize.width < 1200
                    ? {
                        fontSize: '1.3rem',
                      }
                    : null
                }
                className={`${
                  screenSize.width < 1200 ? 'p-3 w-100 ' : ''
                }d-flex align-self-start flex-row gap-2 align-items-center justify-content-center`}
                variant="secondary"
                onClick={() => setModalShow(false)}
              >
                Cancelar
              </Button>
              <Button
                style={
                  screenSize.width < 1200
                    ? {
                        fontSize: '1.3rem',
                      }
                    : null
                }
                className={`${
                  screenSize.width < 1200 ? 'p-3 w-100 ' : ''
                }d-flex align-self-start flex-row gap-2 align-items-center justify-content-center`}
                variant="primary"
                onClick={handleSave}
              >
                Salvar
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
