import Api from "../endpoint"

export const UserServices = {
  Read: () => Api.get("/user/read"),
  Get: (id) => Api.get("/user/" + id),
  Create: (data) => Api.post("/user/create", data),
  Update: (data) => Api.put("/user/update", data),
  Delete: data => Api.delete("/user/delete", { params: data }),
  UpdateImage: data => Api.post("/user/updateImage", data, { headers: { 'Content-Type': 'multipart/form-data' } }),
  GetUser: () => Api.get("/user/user"),
}