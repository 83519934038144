import React, { useContext, useState, useRef, useEffect } from "react"; // Adicione useRef ao import do React
import { Link } from "react-router-dom";
import { Button, Card, Col, Form, Nav, Row } from "react-bootstrap";
import Footer from "../layouts/Footer";
import HeaderMobile from "../layouts/HeaderMobile";
import Avatar from "../components/Avatar";
import { FaEdit, FaSuitcase } from "react-icons/fa"; // Importe o ícone de edição

import img1 from "../assets/img/img1.jpg";
import { UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";
import { swalErro } from "../components/GestecSwall";
import { cnpjMask, cpfMask } from "../Functions/Mask";
import { ScreenContext } from "../context/ScreenContext";

export default function Profile() {
  const { user } = useContext(AuthContext);
  const { updateImage, updateUser, userData, getUser } = useContext(UserContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [profileImage, setProfileImage] = useState(img1);
  const fileInputRef = useRef(null);

  const { screenSize } = useContext(ScreenContext)

  const userStorage = JSON.parse(localStorage.getItem("user_data"));
  useEffect(() => {
    getUser(userStorage)
  }, [])


  const [formData, setFormData] = useState(userData);
  useEffect(() => {
    setFormData(userData)
  }, [userData])

  useEffect(() => {
    console.log(formData)
  }, [formData])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await updateUser(formData);
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'isAdmin' ? checked : value,
    }));
  };

  useEffect(() => {
    setFormData(userData)
  }, [userData])

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      setSelectedImage(e.target.files[0]);
    }


  };
  useEffect(() => {
    if (selectedImage) {
      updateProfileImage()
    }
  }, [selectedImage])


  const handleClickImage = () => {
    fileInputRef.current.click(); // Aciona o clique no input de arquivo
  };

  const updateProfileImage = async () => {
    if (!selectedImage) {
      swalErro("Por favor, selecione uma imagem primeiro.");
      return;
    }
    // Aqui você adicionaria a lógica para enviar a imagem para o servidor
    // e atualizar a imagem do perfil do usuário no backend.
    const payload = {
      file: selectedImage
    }
    const response = await updateImage(payload);
  };

  return (
    <React.Fragment>
      <div className="mt-5 main p-4 p-lg-5">
        <Row className="g-5">
          <Col xl>
            <div className="mb-5 d-flex flex-row gap-3">
              <div className="d-flex flex-column gap-3">
                <div className="media-img mb-3 mb-sm-0" style={{ cursor: 'pointer' }}>
                  <img style={{
                    height: 140
                  }} src={userData && userData.profile_image_path ? "https://api-gestec.qtsys.com.br/" + userData.profile_image_path : profileImage} className="img-fluid rounded" alt="..." />

                  <Form.Control type="file" ref={fileInputRef} onChange={handleImageChange} style={{ display: 'none' }} />

                </div>
                <Button className="d-flex justify-content-center flex-row align-items-center gap-2" onClick={handleClickImage} ><FaEdit className="edit-icon" /> <span> Alterar Logo</span></Button>
              </div>

              <div className="media-body">
                <h2 className="media-name"><strong>{userData && userData.name}</strong></h2>
                <h5 className="d-flex align-items-center gap-2"><FaSuitcase /> <strong>{userData && userData.fantasy_name}</strong></h5>
                <h5 className="d-flex align-items-center gap-2 mb-4"><FaSuitcase /> <strong>{userData && userData?.document?.split("").length > 11 ? cnpjMask(userData?.document) : cpfMask(userData?.document)}</strong></h5>
              </div>
            </div>
          </Col>

        </Row>
        <Row className="g-5">
          <Col>
            <form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
              <div className={`d-flex gap-3 ${screenSize.width < 1100 ? "flex-column" : "flex-row"}`}>
                <Card className={`card-one mt-4 ${screenSize.width < 1100 ? "w-100" : "w-30"} `}>
                  <Card.Body>
                    <Card.Title className="mb-3"><strong>Dados de contato</strong></Card.Title>
                    <div className="d-flex gap-3 flex-column" >
                      <Form.Group controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Digite o email" name="email" value={formData?.email} onChange={handleChange} />
                      </Form.Group>

                      <Form.Group controlId="formGridPhone">
                        <Form.Label>Telefone</Form.Label>
                        <Form.Control type="text" placeholder="Digite o telefone" name="phone" value={formData?.phone} onChange={handleChange} />
                      </Form.Group>
                    </div>
                  </Card.Body>
                </Card>

                <Card className="card-one mt-4 w-100">

                  <Card.Body>
                    <Card.Title className="mb-3"><strong>Dados de endereço</strong></Card.Title>
                    <div className="d-flex gap-3 flex-column" >
                      <div className={`d-flex ${screenSize.width < 1100 ? "flex-column" : "flex-row"} gap-3`}>
                        <Form.Group controlId="formGridPostalCode">
                          <Form.Label>CEP</Form.Label>
                          <Form.Control type="text" placeholder="Digite o CEP" name="postal_code" value={formData?.postal_code} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="w-100" controlId="formGridStreet">
                          <Form.Label>Rua</Form.Label>
                          <Form.Control className="w-100" type="text" placeholder="Digite a rua" name="street" value={formData?.street} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group controlId="formGridNumber">
                          <Form.Label>Número</Form.Label>
                          <Form.Control type="text" placeholder="Digite o número" name="number" value={formData?.number} onChange={handleChange} />
                        </Form.Group>
                      </div>

                      <div className={`d-flex ${screenSize.width < 1100 ? "flex-column" : "flex-row"} gap-3`}>
                        <Form.Group className="w-100" controlId="formGridComplement">
                          <Form.Label>Complemento</Form.Label>
                          <Form.Control type="text" className="w-100" placeholder="Digite o complemento" name="complement" value={formData?.complement} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="w-100" controlId="formGridNeighborhood">
                          <Form.Label>Bairro</Form.Label>
                          <Form.Control type="text" className="w-100" placeholder="Digite o bairro" name="neighborhood" value={formData?.neighborhood} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="w-100" controlId="formGridCity">
                          <Form.Label>Cidade</Form.Label>
                          <Form.Control type="text" className="w-100" placeholder="Digite a cidade" name="city" value={formData?.city} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="w-100" controlId="formGridState">
                          <Form.Label>Estado</Form.Label>
                          <Form.Control type="text" className="w-100" placeholder="Digite o estado" name="state" value={formData?.state} onChange={handleChange} />
                        </Form.Group>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <Card>
                <Card.Body>
                  <Button variant="primary" type="submit">
                    Salvar
                  </Button>
                  <Button variant="secondary" className="ms-2" onClick={() => console.log('Cancelar')}>
                    Cancelar
                  </Button>
                </Card.Body>
              </Card>
            </form>
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment >
  );
}