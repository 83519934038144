import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { OsContext } from '../../context/OsContext';
import { ScreenContext } from '../../context/ScreenContext';

const ModalDetailOs = ({ os }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { screenSize } = useContext(ScreenContext);

  const { update, getOs } = useContext(OsContext);

  const [detail, setDetail] = useState(os.os_last_description);
  useEffect(() => {
    console.log(detail);
  }, [detail]);

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.stopPropagation(); // Prevent event from being handled further up in the DOM
    }
  };

  const handleSubmit = async (e) => {
    const payload = {
      os_last_description: detail,
      id: os.id,
    };

    const response = await update(payload);
    if (response.status === 200) {
      setShow(false);
      getOs(os.id);
    }
  };

  return (
    <>
      <Button
        className={`${
          screenSize.width < 1200 ? `p-3 ` : ''
        }w-100 d-flex align-self-start flex-row gap-2 align-items-center justify-content-center`}
        onClick={handleShow}
      >
        <i className="ri-file-text-line"></i>Adicionar Detalhes
      </Button>

      <Modal
        size="xl"
        fullscreen={screenSize.width < 1200}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {os.locale_name} {os.os_code}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label className="w-100">
            <h4>Detalhamento das Atividades</h4>
            <hr />
          </Form.Label>

          <Form.Control
            rows={15}
            onChange={(e) => setDetail(e.target.value)}
            value={detail} // Certifique-se de vincular o valor ao estado
            name="os_last_description"
            style={{
              fontSize: '1.3rem',
            }}
            id="os_last_description"
            placeholder="Detalhamento"
            onKeyDown={handleKeyDown}
            as={'textarea'}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center flex-row gap-3">
          <Button
            className={`${
              screenSize.width < 1200 ? 'p-3 w-45 ' : ''
            }d-flex align-self-start flex-row align-items-center justify-content-center`}
            onClick={handleClose}
            variant="secondary"
          >
            Cancelar
          </Button>
          <Button
            className={`${
              screenSize.width < 1200 ? 'p-3 w-45 ' : ''
            }d-flex align-self-start flex-row align-items-center justify-content-center`}
            onClick={handleSubmit}
            variant="primary"
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalDetailOs;
