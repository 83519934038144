import React, { createContext, useEffect, useState } from 'react';
import AuthService from '../services/AuthServices';
import LocalStorageItems from '../data/LocalStorageItems';
import { swalErro, swalSucesso } from '../components/GestecSwall';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const token = localStorage.getItem('token');

  const checkLogin = () => {
    if (token && window.location.pathname == '/signin') {
      setTimeout(() => (window.location.href = '/os'), 1000);
    } else if (!token && window.location.pathname !== '/signin') {
      setTimeout(() => (window.location.href = '/signin'), 1000);
    }
  };

  const [user, setUser] = useState([]);

  const login = async (data) => {
    try {
      const payload = {
        email: data.email,
        password: data.password,
      };

      const response = await AuthService.login(payload);
      localStorage.setItem('token', response.data.token);
      if (response.status === 200) {
        swalSucesso('Login realizado com sucesso!');
        setTimeout(() => {
          window.location.href = '/os';
        }, 1000);
        const userData = {
          name: response.data.user.name,
          email: response.data.user.email,
          id: response.data.user.id,
          document: response.data.user.document,
          fantasy_name: response.data.user.fantasy_name,
          email: response.data.user.email,
        };
        localStorage.setItem('user_data', JSON.stringify(userData));
      } else {
        swalErro(response.data.message);
      }
    } catch (error) {
      swalErro(error.response.data.message);
      console.log(error.response.data.message);
    }
  };

  const logout = async () => {
    const payload = {
      email: user.email,
    };
    const response = await AuthService.logout(payload);
    setUser(null);
    localStorage.removeItem('token');
    window.location.href = '/signin'; // Redireciona para a página de login após logout
  };

  const register = async (data) => {
    const response = await AuthService.register(data);
    if (response.status === 200) {
      swalSucesso('Usuário cadastrado com sucesso!');
    } else {
      swalErro(response.data.message);
    }
  };

  const selfRegister = async (data) => {
    const response = await AuthService.selfRegister(data);
    if (response.status === 201) {
      localStorage.setItem('token', response.data.token);
      const userData = {
        name: response.data.user.name,
        email: response.data.user.email,
        id: response.data.user.id,
        document: response.data.user.document,
        fantasy_name: response.data.user.fantasy_name,
        email: response.data.user.email,
      };
      localStorage.setItem('user_data', JSON.stringify(userData));
      swalSucesso('Você foi cadastrado com sucesso!');
      setTimeout(() => {
        window.location.href = '/os';
      }, 1000);
    } else {
      swalErro(response.data.message);
    }
  };

  const sendVerificationCode = async (data) => {
    const payload = {
      email: data.email,
    };

    const response = await AuthService.sendVerificationCode(payload);
    if (response.status === 200) {
      swalSucesso('Código enviado com sucesso!');
    } else {
      swalErro(response.data.message);
    }
  };

  const resetPassword = async (data) => {
    const payload = {
      code: data.code,
      password: data.password,
    };

    const response = await AuthService.resetPassword(payload);
    if (response.status === 200) {
      swalSucesso('Senha alterada com sucesso!');
      setTimeout(() => (window.location.href = '/signin'), 1000);
    } else {
      swalErro('Ocorreu algum erro, tente novamente!');
    }
  };

  const handleSetUser = async (data) => {
    setUser(data);
  };

  return (
    <AuthContext.Provider
      value={{
        // Atributos
        user,
        // Métodos
        login,
        logout,
        register,
        selfRegister,
        resetPassword,
        sendVerificationCode,
        handleSetUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
