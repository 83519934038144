import { createContext, useEffect, useState } from 'react';
import { UserServices } from '../services/UserServices';
import { swalErro, swalSucesso } from '../components/GestecSwall';

const UserContext = createContext();
const UserProvider = ({ children }) => {
  const [userList, setUserList] = useState([]);
  const [userData, setUserData] = useState([]);

  const Read = async () => {
    const response = await UserServices.Read();
    setUserList(response.data.users);
  };

  const updateUser = async (data) => {
    const response = await UserServices.Update(data);
    if (response.status === 200) {
      swalSucesso('Usuário atualizado com sucesso!');
    } else {
      swalErro('Erro ao atualizar usuário!');
    }

    return response;
  };
  const deleteUser = async (data) => {
    const response = await UserServices.Delete(data);
    if (response.status === 200) {
      swalSucesso('Usuário removido com sucesso!');
    } else {
      swalErro('Erro ao remover usuário!');
    }
    return response;
  };

  const updateImage = async (data) => {
    const formData = new FormData();
    formData.append('file', data.file); // Apenas a imagem é anexada ao FormData

    const response = await UserServices.UpdateImage(formData);
    if (response.status === 200) {
      swalSucesso('Imagem atualizada com sucesso!');
    } else {
      swalErro('Erro ao atualizar imagem!');
    }

    return response;
  };

  const getUser = async (data) => {
    const response = await UserServices.GetUser();
    if (response.status === 200) {
      setUserData(response.data.user);
    } else {
      return false;
    }
  };
  return (
    <UserContext.Provider
      value={{
        Read,
        userList,
        updateUser,
        deleteUser,
        updateImage,
        getUser,
        userData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
