import React, { useState, useEffect, useContext } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import pageSvg from '../assets/svg/forgot_password.svg';
import { AuthContext } from '../context/AuthContext';

export default function ResetPassword() {
  const { resetPassword } = useContext(AuthContext);
  const { code } = useParams();

  const [senha, setSenha] = useState('');
  const [conrmaSenha, setConrmaSenha] = useState('');
  const [mostraMensagem, setMostraMensagem] = useState(true);

  useEffect(() => {
    if (senha != conrmaSenha) {
      setMostraMensagem(true);
    } else if (senha == conrmaSenha) {
      setMostraMensagem(false);
    }
  }, [senha, conrmaSenha]);
  const skin = localStorage.getItem('skin-mode');

  const handleReset = async (e) => {
    e.preventDefault();
    const payload = {
      code: code,
      password: senha,
    };

    const response = await resetPassword(payload);
    
  };

  document.body.classList.remove('sidebar-show');

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link to="/" className="header-logo mb-5">
            Gestec
          </Link>
        </Container>
      </div>

      <div className="content">
        <Container className="w-100">
          <Card className="card-auth">
            <Card.Body className="text-center">
              <div className="mb-5">
                <object
                  type="image/svg+xml"
                  data={pageSvg}
                  className="w-50"
                  aria-label="svg image"
                ></object>
              </div>
              <Card.Title>Recuperação de senha de acesso</Card.Title>
              <Card.Text className="mb-5">
                Digite e confirme a sua nova senha abaixo.
              </Card.Text>
              <Card className="card-one">
                <Card.Body>
                  <div className="border-bottom">
                    {mostraMensagem ? (
                      <Alert variant="danger">Senhas não correspondem</Alert>
                    ) : null}
                  </div>
                  <form className="g-3 p-0" onSubmit={handleReset}>
                    <Row className="g-2 p-0 d-flex align-items-center justify-content-center">
                      <Col sm="12" className="g-3 p-0">
                        <Form.Control
                          className="text-center w-100"
                          required
                          type="password"
                          onChange={(e) => setSenha(e.target.value)}
                          name="senha"
                          id="senha"
                          placeholder="Digite sua nova senha"
                        />
                      </Col>

                      <Col sm="12" className="g-3 p-0">
                        <Form.Control
                          className="text-center w-100"
                          required
                          onChange={(e) => setConrmaSenha(e.target.value)}
                          type="password"
                          name="senha"
                          id="senha"
                          placeholder="Confirme a sua senha"
                        />
                      </Col>

                      <Col sm="5">
                        <Button type="submit" className="">
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Card.Body>
              </Card>

              <Row className="p-5">
                <Link to="/">Voltar para tela de Login</Link>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
}
