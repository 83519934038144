import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


import Main from './layouts/Main';
import NotFound from './pages/NotFound';

import publicRoutes from './routes/PublicRoutes';
import protectedRoutes from './routes/ProtectedRoutes';

// import css
import './assets/css/remixicon.css';

// import scss
import './scss/style.scss';
import LoadComponent from './components/LoadComponent/Index';
import { AuthContext, AuthProvider } from './context/AuthContext';
import { UserProvider } from './context/UserContext';
import { OsProvider } from './context/OsContext';
import AuthService from './services/AuthServices';
import { swalErro } from './components/GestecSwall';
import { useStore } from './store/store';

// set skin on load
window.addEventListener('load', function () {
  let skinMode = localStorage.getItem('skin-mode');
  let HTMLTag = document.querySelector('html');

  if (skinMode) {
    HTMLTag.setAttribute('data-skin', skinMode);
  }
});

const isPublicRoute = (currentPath, publicRoutes) => {
  const array = publicRoutes.map(route => route.path.split('/')[0]);
  const path = currentPath.split('/')[1];
  return array.includes(path);
};

export default function App() {
  const { setUser, listCompanies } = useStore((store) => {
    return {
      setUser: store.setUser,
      listCompanies: store.listCompanies,
      isLoading: store.isLoading
    }
  })

  const token = localStorage.getItem('token');
  const { handleSetUser } = useContext(AuthContext)

  const checkLogin = () => {
    if (token && window.location.pathname == '/signin') {
      setTimeout(() => (window.location.href = '/os'), 1000);
    } else if (!token && !isPublicRoute(window.location.pathname, publicRoutes)) {
      setTimeout(() => (window.location.href = '/signin'), 1000);
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  const handleCheckLogin = async () => {
    try {
      const response = await AuthService.check();
      handleSetUser(response.data);
      setUser(response.data);
    } catch (error) {
      swalErro('Você não está logado! Aguarde e será redirecionado para a página de login.');
      localStorage.clear();
      if (!isPublicRoute(window.location.pathname, publicRoutes)) {
        setTimeout(() => (window.location.href = '/signin'), 1000);
      }
    }
  };

  const handleListCompanies = async () => {
    await listCompanies();
  }

  useEffect(() => {
    if (!!token) {
      handleCheckLogin()
      handleListCompanies()
    }
  }, [])


  return (

    <React.Fragment>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}>
            {protectedRoutes.map((route, index) => {
              return (
                <Route path={route.path} element={route.element} key={index} />
              );
            })}
          </Route>
          {publicRoutes.map((route, index) => {
            return (
              <Route path={route.path} element={route.element} key={index} />
            );
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment >
  );
}
