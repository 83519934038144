import Api from "../../../endpoint"

export const createCompaniesSlice = (set, get) => {
  return {
    companies: [],
    osList: [],
    listCompanies: async () => {
      try {
        const response = await Api.get("/companies/list");
        set({ companies: response.data.companies })
      } catch (error) {
        console.log(error)
      }
    },
    listOsFromCompany: async (companyId) => {
      try {
        const response = await Api.post(`/companies/os`, { company_id: companyId });
        set({ osList: response.data.os })
      } catch (error) {
        console.log(error)
      }
    }
  }
}

