import { useContext, useState } from 'react';
import { Button, Card, Form, Modal, Image } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { OsContext } from '../../context/OsContext';
import { ScreenContext } from '../../context/ScreenContext';
import upload from '../../assets/img/upload.svg';
import { FaPlus, FaRegSquarePlus } from 'react-icons/fa6';
import { FiFolderPlus } from 'react-icons/fi';
import { LuImagePlus } from 'react-icons/lu';
import './styles.css';

export const CardAnexo = ({ os }) => {
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null); // Estado para armazenar a URL de preview da imagem
  const { upload, listFiles } = useContext(OsContext);
  const { screenSize } = useContext(ScreenContext);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result); // Atualiza a URL de preview com o resultado do FileReader
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewUrl(null); // Limpa o preview se nenhum arquivo for selecionado
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      os_id: os.id,
      file: file,
      description: description,
    };

    const response = await upload(payload);

    if (response.status === 200) {
      setDescription('');
      setFile(null);
      setPreviewUrl(null);
      listFiles(os);
    }
  };

  return (
    <>
      <Card
        style={{ height: previewUrl ? '100%' : '470px' }}
        className="card-one custom-card-file d-flex flex-column justify-content-center align-items-center"
      >
        <Card.Body className={previewUrl ? 'w-100' : 'w-100 p-0'}>
          <Form className="d-flex h-100 flex-column gap-3">
            {previewUrl && (
              <Form.Group>
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Descrição"
                  value={description}
                  onChange={handleDescriptionChange}
                  className={`p-2`} // Ajuste para mobile
                />
              </Form.Group>
            )}
            {!previewUrl && (
              <Form.Group className="h-100 w-100">
                <div
                  {...getRootProps()}
                  className="dropzone p-3 w-100"
                  style={{
                    height: '100%',
                    minHeight: '150px',
                    display: 'flex',
                    cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <input {...getInputProps()} />
                  <span className="w-100 d-flex flex-row justify-content-center align-items-center">
                    <LuImagePlus className="custom-image-svg" size={100} />
                  </span>
                </div>
              </Form.Group>
            )}
            {previewUrl && (
              <div className="preview-container">
                <Image src={previewUrl} alt="Preview" thumbnail />
              </div>
            )}
          </Form>
        </Card.Body>
        {previewUrl && (
          <Card.Footer className="d-flex flex-row gap-3">
            <Button
              onClick={() => {
                setDescription('');
                setFile(null);
                setPreviewUrl(null);
              }}
              variant="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={handleSubmit} variant="primary">
              Salvar Alterações
            </Button>
          </Card.Footer>
        )}
      </Card>
    </>
  );
};
