import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, Col, Dropdown, Nav, Row } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import Avatar from "../components/Avatar";

import img6 from "../assets/img/img6.jpg";
import img7 from "../assets/img/img7.jpg";
import img8 from "../assets/img/img8.jpg";
import img9 from "../assets/img/img9.jpg";
import img10 from "../assets/img/img10.jpg";
import img11 from "../assets/img/img11.jpg";
import img12 from "../assets/img/img12.jpg";
import img13 from "../assets/img/img13.jpg";
import img14 from "../assets/img/img14.jpg";
import img15 from "../assets/img/img15.jpg";
import img16 from "../assets/img/img16.jpg";
import img17 from "../assets/img/img17.jpg";
import img18 from "../assets/img/img18.jpg";
import img19 from "../assets/img/img19.jpg";
import { UserContext } from "../context/UserContext";
import { celularMask } from "../Functions/Mask";

export default function Contacts() {
  const { userList, Read } = useContext(UserContext);
  const [selectedUser, setSelectedUser] = useState(null);
  useEffect(() => {
    Read()
  }, [])


  useEffect(() => {
    document.body.classList.add('page-app')
    return () => {
      document.body.classList.remove('page-app')
    }
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="header-link"
    >
      {children}
    </Link>
  ));

  const contactGroup = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  // toggle contact sidebar in mobile
  const [isSidebarShow, setSidebarShow] = useState(false);

  const sidebarShow = () => {
    setSidebarShow(!isSidebarShow);
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className={"contact-panel " + (isSidebarShow ? "sidebar-show" : "")}>
          <div className="contact-body">
            <div className="contact-list">
              <div className="d-flex flex-column">
                <div className="mb-4">
                  <Button variant="primary" className="btn-contact-new">
                    <i className="ri-add-fill"></i> Novo usuário
                  </Button>
                </div>
                <div className="d-flex flex-row contact-list-header">
                  <h6 className="sidebar-title me-auto">Usuários</h6>

                  <Dropdown align="end">
                    <Dropdown.Toggle as={CustomToggle}>
                      <i className="ri-more-2-fill"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href=""><i className="ri-contacts-book-2-line"></i> Display Density</Dropdown.Item>
                      <Dropdown.Item href=""><i className="ri-list-check-2"></i> Filter Contacts</Dropdown.Item>
                      <Dropdown.Item href=""><i className="ri-list-settings-line"></i> Contact Settings</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <PerfectScrollbar className="contact-list-body">
                {contactGroup.map((contact, index) => {
                  if (userList.some((user) => user.name.charAt(0) === contact)) {
                    return (
                      <React.Fragment key={index}>
                        <label className="sidebar-label mb-2">{contact}</label>
                        <div className="contact-group mb-3">
                          {userList.map((item, ind) => {
                            if (item.name.charAt(0) === contact) {
                              return (
                                <div key={ind} onClick={() => setSelectedUser(item)} className={selectedUser?.id == item.id ? "contact-item selected" : "contact-item"}>
                                  <div className={"avatar " + item.status}>
                                    {item.thumb && <img src={item.thumb} alt="" />}
                                    {item.initial && <span className={"avatar-initial text-white bg-" + item.initial}>{contact.group}</span>}
                                  </div>
                                  <div className="contact-item-body">
                                    <div className="d-flex align-items-center mb-1">
                                      <h6 className="mb-0">{item.name}</h6>
                                    </div>
                                    <span>{item.contact}</span>
                                  </div>
                                  <Nav className="nav-icon">
                                    <Nav.Link href=""><i className="ri-star-line"></i></Nav.Link>
                                    <Nav.Link href=""><i className="ri-pencil-line"></i></Nav.Link>
                                  </Nav>
                                </div>
                              )
                            }
                          })}
                        </div>
                      </React.Fragment>
                    )
                  }
                })}
              </PerfectScrollbar>

            </div>

            <PerfectScrollbar className="contact-content">
              <Link id="contactClose" href="" className="contact-close"><i className="ri-close-fill"></i></Link>
              <div className="d-sm-flex p-2 p-sm-4 p-md-2 p-xl-4">
                <div className="me-4 mb-3 mb-sm-0"><Avatar img={img11} /></div>
                <div className="flex-fill">
                  <h4 className="mb-1 text-dark fw-semibold">{selectedUser?.name}</h4>
                  <p>{selectedUser?.fantasy_name}</p>

                </div>
              </div>

              <hr />

              <span>
                <span>Telefone: </span>
                <strong className="fw-semibold">{celularMask(selectedUser?.phone)}</strong>
              </span>
              <hr />

              <span>
                <span>Email: </span>
                <strong className="fw-semibold">{selectedUser?.email}</strong>
              </span>
              <hr />




              <hr />

              <Nav className="nav-sidebar">
                <Nav.Link href=""><i className="ri-share-forward-line"></i> Editar</Nav.Link>
                <Nav.Link href=""><i className="ri-delete-bin-line"></i> Excluir</Nav.Link>
              </Nav>

            </PerfectScrollbar>

          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
}