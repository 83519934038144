import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL;
// const baseUrl = "http://127.0.0.1:8000/";

const Api = axios.create({
  baseURL: baseUrl + "api",
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  }
})

export default Api;