import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import bg1 from "../assets/img/bg1.jpg";
import { AuthContext } from "../context/AuthContext";
import { swalErro, swalSucesso, toastSucesso } from "../components/GestecSwall";
import { ScreenContext } from "../context/ScreenContext";

export default function Signin2() {
  const { login } = useContext(AuthContext);
  const { screenSize } = useContext(ScreenContext);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      email: e.target.email.value,
      password: e.target.password.value
    }

    await login(payload);
  }
  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="5" xl="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Link to="/" className="header-logo mb-5">Gestec</Link>
              <Card.Title>Entrar</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <Form.Label>Email</Form.Label>
                  <Form.Control className={`${screenSize.width < 1200 ? "p-4" : null}`} type="text" placeholder="Digite o seu e-mail" name="email" id="email" />
                </div>
                <div className="mb-4">
                  <Form.Label className="d-flex justify-content-between">
                    Senha <Link to="/forgot">Esqueceu sua senha?</Link>
                  </Form.Label>
                  <Form.Control className={`${screenSize.width < 1200 ? "p-4" : null}`} type="password" placeholder="Digite a sua senha" name="password" id="password" />
                </div>
                <Button type="submit" className={`${screenSize.width < 1200 ? "p-4" : null} btn-sign`}>Entrar</Button>
              </Form>
              <hr />
            </Card.Body>
            <div className="divider"><span>Não possui uma conta?</span></div>
            <Card.Footer>
              <Link to="/signup">Criar minha conta</Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col className="d-none d-lg-block">
          <img src={bg1} className="auth-img" alt="" />
        </Col>
      </Row>
    </div>
  )
}