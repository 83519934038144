import { createContext, useEffect, useState } from 'react';
import OsService from '../services/OsService';
import { swalErro, swalSucesso } from '../components/GestecSwall';
import { trackPromise } from 'react-promise-tracker';

const OsContext = createContext();

const OsProvider = ({ children }) => {
  const [osList, setOsList] = useState([]);
  const [os, setOs] = useState([]);
  const [osFiles, setOsFiles] = useState([]);
  const [osStatusList, setOsStatusList] = useState([]);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  const read = async () => {
    const response = await trackPromise(OsService.read());
    setOsList(response.data.os);
  };

  const create = async (data) => {
    const response = await trackPromise(OsService.create(data));
    if (response.status === 200) {
      setOsList([...osList, response.data.os]);
      swalSucesso('Ordem de Serviço criada com sucesso!');
      return true;
    } else {
      swalErro(response.data.message);
      return false;
    }
  };

  const update = async (data) => {
    const response = await OsService.update(data);
    if (response.status === 200) {
      swalSucesso('Ordem de Serviço atualizada com sucesso!');
    } else {
      swalErro(response.data.message);
    }

    return response;
  };
  const deleteOs = async (data) => {
    const payload = {
      os: data,
    };
    const response = await OsService.delete(payload);
    if (response.status === 200) {
      // setOsList(osList.filter((os) => os.id !== data.id));
      swalSucesso('Ordem de Serviço deletada com sucesso!');
    } else {
      swalErro(response.data.message);
    }
  };

  const getOs = async (id) => {
    const payload = {
      id: id,
    };

    const response = await OsService.get(payload);
    if (response.data.os) {
      setOs(response.data.os);
    } else {
      swalErro('Nenhuma atividade encontrada!');

      setTimeout(() => (window.location.href = '/os'), 1000);
    }
  };

  async function upload(data) {
    const formData = new FormData();
    formData.append('os', data.os_id);
    formData.append('file', data.file);
    formData.append('description', data.description);

    const response = await OsService.upload(formData);
    if (response.status === 200) {
      swalSucesso(response.data.message);
    } else {
      swalErro(response.data.message);
    }
    return response;
  }

  const deleteFile = async (data) => {
    const response = await OsService.deleteFile(data);
    return response;
  };

  const listFiles = async (id) => {
    const payload = {
      os: id,
    };
    const response = await OsService.listFiles(payload);
    setOsFiles(response.data.files);
  };

  const getPDF = async (id) => {
    setIsGeneratingPdf(true);
    const payload = { id: id };
    const response = await OsService.getPdf(payload);
    // Adicione responseType: 'blob'
    window.open(response.data.url, '_blank');
    setTimeout(() => {
      setIsGeneratingPdf(false);
    }, 1000);
  };

  const osFilesUpdate = async (data) => {
    const response = await OsService.osFilesUpdate(data);
    if (response.status === 200) {
      swalSucesso(response.data.message);
      return true;
    } else {
      swalErro(response.data.message);
    }
    return response;
  };

  const osStatus = async (data) => {
    const response = await OsService.osStatus(data);
    setOsStatusList(response.data.status);
  };

  return (
    <OsContext.Provider
      value={{
        // Atributos
        isGeneratingPdf,
        os,
        osList,
        osFiles,
        osStatusList,

        // Métodos
        read,
        create,
        update,
        deleteOs,
        getOs,
        upload,
        deleteFile,
        listFiles,
        getPDF,
        osStatus,
        osFilesUpdate,
      }}
    >
      {children}
    </OsContext.Provider>
  );
};

export { OsContext, OsProvider };
