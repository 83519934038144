import React, { useContext } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import bg1 from "../assets/img/bg1.jpg";
import { AuthContext } from "../context/AuthContext";
import { toastSucesso } from "../components/GestecSwall";

export default function Signup2() {
  const { selfRegister } = useContext(AuthContext);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const payload = {
      email: formData.get('email'),
      password: formData.get('password'),
      fantasy_name: formData.get('fantasy_name'),
      document: formData.get('document'),
      phone: formData.get('phone'),
      name: formData.get('name'),
    };

    const response = await selfRegister(payload);
    if (response.data.status == "success") {
      toastSucesso("Conta criada com sucesso!");
    }
  }
  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="5" xl="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Link to="/" className="header-logo mb-5">Gestec</Link>
              <Card.Title>Criar acesso</Card.Title>
            </Card.Header>
            <form onSubmit={handleSubmit}>
              <Card.Body>
                <div className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control name="email" id="email" type="text" placeholder="Digite seu endereço de e-mail" />
                </div>
                <div className="mb-3">
                  <Form.Label>Senha</Form.Label>
                  <Form.Control name="password" id="password" type="password" placeholder="Digite sua senha" />
                </div>
                <div className="mb-3">
                  <Form.Label>Nome completo</Form.Label>
                  <Form.Control id="name" name="name" type="text" placeholder="Digite seu nome completo" />
                </div>
                <div className="mb-3">
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control id="phone" name="phone" type="text" placeholder="Digite seu telefone para contato " />
                </div>
                <div className="mb-3">
                  <Form.Label>Nome fantasia</Form.Label>
                  <Form.Control id="fantasy_name" name="fantasy_name" type="text" placeholder="Digite o nome fantasia de sua empresa" />
                </div>

                <div className="mb-3">
                  <Form.Label>CPF / CNPJ</Form.Label>
                  <Form.Control name="document" id="document" type="text" placeholder="CPF ou CNPJ" />
                </div>
                {/* <div className="mb-4">
                <small>Clicando <strong>Create Account</strong> below, you agree to our terms of service and privacy statement.</small>
              </div> */}
                <Button variant="primary" type="submit" className="btn-sign">Criar Conta</Button>
              </Card.Body>
            </form>

            <div className="divider"><span>Já possui uma conta?</span></div>
            <Card.Footer>
              <Link to="/signin">Entrar</Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col className="d-none d-lg-block">
          <img src={bg1} className="auth-img" alt="" />
        </Col>
      </Row>
    </div>
  );
}