import { useContext, useEffect, useState } from 'react';
import { OsContext } from '../../context/OsContext';
import { Badge, Button, Card, Form, Modal } from 'react-bootstrap';
import { ScreenContext } from '../../context/ScreenContext';
import { dialog } from '../GestecSwall';
import { FaCheckCircle, FaInfoCircle } from 'react-icons/fa';
import { BsInfoSquare } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';

export const AlterarStatusOs = ({ os }) => {
  const location = useLocation();
  useEffect(() => {
    console.log(location.pathname);
  }, []);
  const [show, setShow] = useState(false);
  const { screenSize } = useContext(ScreenContext);
  const { osStatusList, osStatus, update, read, getOs } = useContext(OsContext);
  const [selectedStatus, setSelectedStatus] = useState({});
  const handleOpenModal = () => {
    setShow(true);
    osStatus();
  };

  const handleSelectStatus = (event) => {
    const { value, selectedIndex, options } = event.target;
    const name = options[selectedIndex].text;
    setSelectedStatus({
      id: value,
      name: name,
    });
  };

  useEffect(() => {
    console.log(selectedStatus);
  }, [selectedStatus]);

  const handleUpdateStatus = async (data) => {
    const payload = {
      id: os.id,
      os_status: selectedStatus.id,
    };
    const confirm = await dialog(
      `Deseja alterar o status da OS para ${selectedStatus.name}?`
    );
    if (confirm.isConfirmed) {
      const response = await update(payload);
      if (response.status === 200) {
        setShow(false);
        read();
        if (location.pathname == `/os/${os.id}`) {
          getOs(os.id);
        }
      }
    }
  };
  return (
    <>
      <Button
        className={`${
          screenSize.width < 1200 ? 'p-3 ' : ''
        } w-100  d-flex align-self-start flex-row gap-2 align-items-center justify-content-center`}
        onClick={handleOpenModal}
      >
        <BsInfoSquare /> Alterar Status
      </Button>
      <Modal
        fullscreen={screenSize.width < 1200}
        size="xl"
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Alterar Status</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={`d-flex ${
            screenSize.width < 1200 ? 'flex-column' : 'flex-row'
          } gap-3`}
        >
          <Card className={`${screenSize.width < 1200 ? 'w-100' : 'w-60'}`}>
            <Card.Body>
              <Card.Title>Alterar status da OS</Card.Title>
              <Form.Select onChange={handleSelectStatus}>
                {osStatusList?.map((status, index) => (
                  <option key={index} value={status.id}>
                    {status.status_name}
                  </option>
                ))}
              </Form.Select>
            </Card.Body>
          </Card>

          <Card className={`${screenSize.width < 1200 ? 'w-100' : 'w-40'}`}>
            <Card.Body>
              <div className="d-flex flex-column">
                <span>
                  <strong>Código</strong>: {os.os_code}
                </span>
                <span>
                  <strong>Local</strong>: {os.locale_name}
                </span>
                <span className="">
                  <strong>Status</strong>:
                  <span
                    className="mx-2 px-2 rounded"
                    style={{
                      minWidth: '60px',
                      maxWidth: '120px',
                      backgroundColor: os.status_color,
                      color: os.os_status_text_color,
                    }}
                  >
                    {os.status_name}
                  </span>
                </span>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row gap-3 justify-content-center">
          <Button
            className={`${
              screenSize.width < 1200 ? 'p-3 w-45 ' : ''
            }d-flex align-self-start flex-row gap-2 align-items-center justify-content-center`}
            onClick={handleUpdateStatus}
          >
            Alterar
          </Button>
          <Button
            className={`${
              screenSize.width < 1200 ? 'p-3 w-45 ' : ''
            }d-flex align-self-start flex-row gap-2 align-items-center justify-content-center`}
            variant="secondary"
            onClick={() => setShow(false)}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
