import { useContext, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { AuthContext } from '../../../context/AuthContext';
import { ScreenContext } from '../../../context/ScreenContext';

const CadastroUsuario = () => {
  const { register } = useContext(AuthContext);
  const { screenSize } = useContext(ScreenContext);
  const [modalShow, setModalShow] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault(); 
    const formData = new FormData(e.target);
    const data = {
      email: formData.get('email'),
      password: formData.get('password'),
      fantasy_name: formData.get('fantasy_name'),
      document: formData.get('document'),
      phone: formData.get('phone'),
      type: formData.get('type'),
      name: formData.get('name'),
      is_admin: formData.get('is_admin'),
    };
    const response = await register(data);
    if(response.status === 200){
      setModalShow(false);
    }
  };
  return (
    <>
      <Button onClick={() => setModalShow(true)} className="w-100">
        <i className="ri-add-fill"></i> Novo usuário
      </Button>
      <Modal
        size="xl"
        fullscreen={screenSize.width < 1200}
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cadastro de Novo Acesso</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                id="email"
                name="email"
                type="email"
                placeholder="Digite o email"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Senha</Form.Label>
              <Form.Control
                id="password"
                name="password"
                type="password"
                placeholder="Digite a senha"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Nome Fantasia</Form.Label>
              <Form.Control
                id="fantasy_name"
                name="fantasy_name"
                type="text"
                placeholder="Digite o nome fantasia"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Documento</Form.Label>
              <Form.Control
                id="document"
                name="document"
                type="text"
                placeholder="Digite o documento"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Telefone</Form.Label>
              <Form.Control
                id="phone"
                name="phone"
                type="text"
                placeholder="Digite o telefone"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Tipo</Form.Label>
              <Form.Select name="is_admin" id="is_admin">
                <option value="0">Usuário</option>
                <option value="1">Administrador</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                id="name"
                name="name"
                type="text"
                placeholder="Digite o nome"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalShow(false)} variant="secondary">
              Cancelar
            </Button>
            <Button type="submit" variant="primary">
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default CadastroUsuario;
