import { useContext, useState } from 'react';
import { OsContext } from '../../../context/OsContext';
import { Button, Modal } from 'react-bootstrap';
import { dialog, toastErro, toastSucesso } from '../../GestecSwall';

export default function RemoverAnexo({ os, item }) {
  const { deleteFile, listFiles } = useContext(OsContext);
  const [show, setShow] = useState(false);
  const handleRemove = async () => {
    const confirma = await dialog('Deseja realmente remover este anexo?');
    if (confirma.isConfirmed) {
      const response = await deleteFile(item);
      if (response.status === 200) {
        toastSucesso('Anexo removido com sucesso!');
        listFiles(os);
        setShow(false);
      } else {
        toastErro('Erro ao remover anexo! Entre em contato com o suporte!');
      }
    }
  };

  return (
    <>
      <Button onClick={handleRemove} className="">
        <i class="ri-delete-bin-line"></i> <span>Excluír Imagem</span>
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header>Excluir imagem</Modal.Header>
        <Modal.Body></Modal.Body>
      </Modal>
    </>
  );
}
