import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <div className="main-footer">
      <span>&copy; {year}. Quantum Systems. All Rights Reserved.</span>
      <span>Created by: <Link to="#">Quantum Systems</Link></span>
    </div>
  )
}