import React, { useContext, useEffect } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { OsContext } from '../../context/OsContext';
import { ModalCriaOs } from '../../components/ModalCriaOs';
import { OsListComponent } from '../../components/Os/List';
import { OsCardComponent } from '../../components/Os/Cards';
import { ScreenContext } from '../../context/ScreenContext';

const OsPage = () => {
  const { osList, read } = useContext(OsContext);
  const { screenSize } = useContext(ScreenContext);
  useEffect(() => {
    read();
  }, []);

  return (
    <React.Fragment>
      <div className="main main-app p-3 p-lg-4">
        <div>
          <Card className="mb-3">
            <Card.Body
              className={`d-flex align-items-center justify-content-between`}
            >
              <div>
                <h4 className="main-title mb-0">Lista de Atividades</h4>
              </div>
              <ModalCriaOs />
            </Card.Body>
          </Card>
        </div>

        {screenSize.width < 1200 ? (
          <OsCardComponent osList={osList} />
        ) : (
          <Card className="card-one">
            <Card.Body>
              {' '}
              <OsListComponent osList={osList} />{' '}
            </Card.Body>
          </Card>
        )}
      </div>
    </React.Fragment>
  );
};

export default OsPage;
