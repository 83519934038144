import { create } from 'zustand'
import { createAuthSlice } from './slices/auth/AuthSlice'
import { createCompaniesSlice } from './slices/companies/CompaniesSlice'
import { createLoadSlice } from './slices/Load/loadSlice'
export const useStore = create((store) => {
  return {
    ...createAuthSlice(store),
    ...createCompaniesSlice(store),
    ...createLoadSlice(store)
  }
})