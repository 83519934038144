import { createContext, useContext, useState } from 'react';
import { DashboardServices } from '../services/DashboardServices';

const DashboardContext = createContext();
export const DashboardProvider = ({ children }) => {
  const [osDashboardData, setOsDashboardData] = useState([]);
  const getOsDataToDashboard = async () => {
    const payload = {
      company_id: '2',
    };

    const response = await DashboardServices.getOsDataToDashboard(payload);
    setOsDashboardData(response.data.os_data);
  };
  return (
    <DashboardContext.Provider
      value={{ osDashboardData, getOsDataToDashboard }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => useContext(DashboardContext);
