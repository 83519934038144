import { useContext, useEffect, useState } from 'react';
import { Button, Card, Form, Modal, Nav } from 'react-bootstrap';
import { ScreenContext } from '../../../context/ScreenContext';
import { AuthContext } from '../../../context/AuthContext';
import { UserContext } from '../../../context/UserContext';

const EditaUsuario = ({ user }) => {
  const { Read, updateUser } = useContext(UserContext);
  const [modalShow, setModalShow] = useState(false);
  const [userData, setUserData] = useState({});
  useEffect(() => {
    setUserData(user);
  }, [user]);
  const { screenSize } = useContext(ScreenContext);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      id: user.id,
      email: formData.get('email'),
      fantasy_name: formData.get('fantasy_name'),
      document: formData.get('document'),
      phone: formData.get('phone'),
      name: formData.get('name'),
    };
    const response = await updateUser(data);
    if (response.status === 200) {
      setModalShow(false);
      Read();
    }
  };

  return (
    <>
      <Nav.Link onClick={() => setModalShow(true)}>
        <i className="ri-edit-2-line"></i> Editar
      </Nav.Link>
      <Modal
        size="xl"
        fullscreen={screenSize.width < 1200}
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{userData?.name}</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <Card>
              <Card.Body>
                <Form.Group>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Digite o nome"
                    defaultValue={user?.name}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Digite o email"
                    defaultValue={user?.email}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder="Digite o telefone"
                    defaultValue={user?.phone}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Nome Fantasia</Form.Label>
                  <Form.Control
                    id="fantasy_name"
                    name="fantasy_name"
                    type="text"
                    placeholder="Digite o nome fantasia"
                    defaultValue={user?.fantasy_name}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>CPF / CNPJ</Form.Label>
                  <Form.Control
                    id="document"
                    name="document"
                    type="text"
                    placeholder="Digite o CPF ou CNPJ"
                    defaultValue={user?.document}
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default EditaUsuario;
