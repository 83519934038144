import Api from "../endpoint";

const OsService = {
  create: (data) => Api.post("/os/create", data),
  update: (data) => Api.put("/os/update", data),
  delete: (data) => Api.delete("/os/delete", { params: data }),
  get: (data) => Api.get("/os/get", { params: data }),
  read: (data) => Api.get("/os/read", data),
  upload: (data) => Api.post("/os/file/upload", data, { headers: { 'Content-Type': 'multipart/form-data' } }),
  deleteFile: (data) => Api.delete("/os/file/delete", { params: data }),
  listFiles: (data) => Api.get("/os/file/list", { params: data }),
  getPdf: (data) => Api.post("/pdf/os", data),
  osStatus: () => Api.get("/os/status"),
  osFilesUpdate: (data) => Api.put("/os/file/update", data),
}

export default OsService;