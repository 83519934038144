import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { OsContext } from '../../../context/OsContext';
import { Button, Card, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { ScreenContext } from '../../../context/ScreenContext';
import { CardAnexo } from '../../../components/ModalAnexo';
import { ModalCriaOs } from '../../../components/ModalCriaOs';
import Swal from 'sweetalert2';
import ModalDetailOs from '../../../components/ModalDetailOs/ModalDetailOs';
import { FaArrowLeftLong } from 'react-icons/fa6';
import RemoverAnexo from '../../../components/ModalAnexo/Remover';
import { ModalEditaOs } from '../../../components/ModalEditaOs/EditaOs';
import { AlterarStatusOs } from '../../../components/ModalEditaOs/AlteraStatusOs';
import ModalEditaImagem from '../../../components/ModalEditaImagem/ModalEditaImagem';
import { useStore } from '../../../store/store';

export default function OsDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    os,
    osFiles,
    getOs,
    update,
    listFiles,
    deleteOs,
    getPDF,
    isGeneratingPdf,
  } = useContext(OsContext);

  const { screenSize } = useContext(ScreenContext);
  const [page, setPage] = useState(1);

  const [editMode, setEditMode] = useState(false);
  const [modalAnexo, setModalAnexo] = useState({});
  const [modalAnexoShow, setModalAnexoShow] = useState(false);
  const handleOpenModalAnexo = (descricao, anexo) => {
    setModalAnexo({ descricao: descricao, anexo: anexo });
    setModalAnexoShow(true);
  };

  const handleGetPdf = async () => {
    const response = await getPDF(id);
  };

  const handleDelete = () => {
    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter esta ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#171717',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, excluir!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteOs(id);
        setTimeout(() => {
          navigate('/os');
        }, 1500);
      }
    });
  };

  useEffect(() => {
    getOs(id);
    listFiles(id);
  }, []);

  const [openModalEdicoes, setOpenModalOpcoes] = useState(false);
  const handleOpemModalOpcoes = async () => {
    setOpenModalOpcoes(true);
  };

  return (
    <React.Fragment>
      <div className="main main-app p-3 p-lg-4 d-flex flex-column gap-3">
        <Card className="card-one">
          <Card.Body
            className={`d-flex ${
              screenSize.width < 1200 ? 'w-100 flex-column gap-3' : 'flex-row'
            } justify-content-between align-items-center`}
          >
            <div
              className={`d-flex ${
                screenSize.width < 1200 ? 'w-100 flex-column' : 'flex-row'
              } gap-3 p-0 align-items-center`}
            >
              {/* <Link className="h-100" to="/os">
                <Button
                  style={{ fontSize: '20px' }}
                  className="p-3 d-flex rounded-pill align-self-start flex-row align-items-center justify-content-center"
                >
                  <FaArrowLeftLong />
                </Button>
              </Link> */}
              <Card.Title
                className={`d-flex  ${
                  screenSize.width < 1200
                    ? 'w-100 px-0 w-100 flex-column gap-3 align-items-center'
                    : 'flex-row gap-2 align-items-center'
                }`}
              >
                {screenSize.width < 1200 ? (
                  <div className="d-flex w-100 flex-column gap-2">
                    <strong>{os.locale_name}</strong>
                    <strong className="text-danger">(OS-{os.os_code})</strong>
                  </div>
                ) : (
                  <>
                    <strong>Ordem de Serviço: {os.locale_name}</strong>
                    <strong className="text-danger">(OS-{os.os_code})</strong>
                  </>
                )}
                <span
                  className={`rounded ${
                    screenSize.width < 1200
                      ? 'w-100 p-1  text-center'
                      : 'px-3 py-1'
                  }`}
                  style={{
                    color: os.os_status_text_color,
                    backgroundColor: os.status_color,
                  }}
                >
                  {os.status_name}
                </span>
              </Card.Title>
            </div>

            {screenSize.width < 1200 ? (
              <></>
            ) : (
              <div className="d-flex flex-row gap-2">
                <Button
                  className={`${
                    screenSize.width < 1200 ? 'p-3 w-100 ' : ''
                  }d-flex align-self-start flex-row gap-2 align-items-center justify-content-center`}
                  onClick={handleOpemModalOpcoes}
                >
                  Opções
                </Button>
              </div>
            )}
          </Card.Body>
        </Card>
        {screenSize.width < 1200 && (
          <Card className="card-one">
            <Button
              className={`${
                screenSize.width < 1200 ? ' w-100 ' : ''
              }p-3 d-flex align-self-start flex-row gap-2 align-items-center justify-content-center`}
              onClick={handleOpemModalOpcoes}
            >
              Opções
            </Button>
          </Card>
        )}
        <Card className="card-one">
          <Card.Header>
            <Card.Title className="d-flex flex-row justify-content-between w-100">
              <h5>
                <strong>Detalhes da Atividade</strong>
              </h5>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row className="row">
              <Col md="12" xl="8" className="">
                <Card className="mb-4">
                  <Card.Body>
                    <Card.Title className="d-flex flex-row justify-content-between">
                      <strong className="d-flex flex-row gap-1 align-items-center">
                        <i className="ri-map-pin-line"></i>
                        <span>Endereço</span>
                      </strong>
                    </Card.Title>
                    <p>
                      {os.locale_street}, {os.locale_number}
                      {os.locale_complement && `, ${os.locale_complement}`}
                      <br />
                      {os.locale_neighborhood}, {os.locale_city} -{' '}
                      {os.locale_state}
                    </p>
                  </Card.Body>
                </Card>

                <Card className="mb-4">
                  <Card.Body>
                    <div className="d-flex flex-column gap-1">
                      <span>
                        <strong>Data de Início</strong>:{' '}
                        {new Date(os.start_date).toLocaleDateString()}
                      </span>
                      <span>
                        <strong>Data de Término</strong>:{' '}
                        {os.end_date
                          ? new Date(os.end_date).toLocaleDateString()
                          : 'Não definido'}
                      </span>
                      <span>
                        <strong>Criado em</strong>:{' '}
                        {new Date(os.created_at).toLocaleDateString()}
                      </span>
                      <span>
                        <strong>Última atualização</strong>:{' '}
                        {new Date(os.updated_at).toLocaleDateString()}
                      </span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={12} lg={4}>
                <div className="d-flex flex-column gap-3">
                  <Card>
                    <Card.Body>
                      <Card.Title className="d-flex flex-row gap-3 justify-content-between">
                        <strong className="d-flex flex-row gap-1 align-items-center">
                          <i className="ri-book-open-line"></i>Detalhamento
                        </strong>
                      </Card.Title>
                      <span
                        style={{
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {os.os_description}
                      </span>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="card-one">
          <Card.Header>
            <Card.Title>
              <strong>Anexos</strong>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row className="gy-3">
              <Col md={4} xl={3} xs={12}>
                <CardAnexo os={os} />{' '}
                {/* Define a mesma altura para o card de anexo */}
              </Col>
              {osFiles &&
                osFiles.map((item, index) => (
                  <Col md={4} xl={3} xs={12} key={index}>
                    <Card
                      style={{
                        backgroundColor: 'rgb(255, 255, 255)',
                        color: 'rgb(42, 53, 71)',
                        transition:
                          'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        borderRadius: '7px',
                        boxShadow:
                          'rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
                        overflow: 'hidden',
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'none',
                        padding: ' 0px',
                        position: 'relative',
                      }}
                    >
                      {' '}
                      {/* Define uma altura fixa para o card */}
                      <Card.Img
                        variant="top"
                        style={{
                          display: 'block',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center center',
                          width: '100%',
                          height: '300px',
                          objectFit: 'cover',
                        }}
                        src={`${process.env.REACT_APP_API_URL}${item.file_path}`}
                      />
                      <Card.Body className="d-flex flex-column justify-content-between align-items-center">
                        <Card.Title className="text-dark d-flex flex-column justify-content-between align-items-between h-100 w-100">
                          <div>{item.file_description}</div>
                          <div className="d-flex flex-row gap-3 justify-content-between">
                            <RemoverAnexo os={id} item={item} />
                            <ModalEditaImagem os={id} item={item} />
                          </div>
                        </Card.Title>
                        {/* <div className="d-flex flex-row justify-content-end align-items-end w-100">
                          <Dropdown>
                            <Dropdown.Toggle>Opções</Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <ModalEditaImagem os={id} item={item} />
                              </Dropdown.Item>
                              <Dropdown.Item>
                               
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div> */}
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Row>
          </Card.Body>
        </Card>

        <Modal
          size="xl"
          show={modalAnexoShow}
          onHide={() => setModalAnexoShow(false)}
        >
          <Modal.Body>
            <Modal.Title>{modalAnexo.descricao}</Modal.Title>
            <img width={'100%'} src={modalAnexo.anexo} alt="anexo" />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setModalAnexoShow(false)}
            >
              Fechar
            </Button>
            <a
              href={modalAnexo.anexo}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="primary">Visualizar</Button>
            </a>
          </Modal.Footer>
        </Modal>

        <Modal
          centered
          // fullscreen={screenSize.width < 1200}
          show={openModalEdicoes}
          onHide={() => setOpenModalOpcoes(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Opções</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <Card.Body className="d-flex flex-column gap-3">
                <div className="w-100">
                  <AlterarStatusOs os={os} />
                </div>

                <Button
                  onClick={() => handleGetPdf()}
                  className={`${
                    screenSize.width < 1200 ? `p-3 ` : ''
                  } w-100 d-flex align-self-start flex-row gap-2 align-items-center justify-content-center`}
                >
                  <i className="ri-file-text-line"></i> Relatório
                </Button>

                <ModalEditaOs os={id} />

                <ModalDetailOs os={os} />

                <Button
                  onClick={handleDelete}
                  className={`${
                    screenSize.width < 1200 ? `p-3 ` : ''
                  }w-100 d-flex align-self-start flex-row gap-2 align-items-center justify-content-center`}
                >
                  <i className="ri-delete-bin-line"></i> Deletar
                </Button>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className={` p-3 d-flex align-self-start flex-row gap-2 align-items-center justify-content-center`}
              onClick={() => setOpenModalOpcoes(false)}
            >
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}
