import { useContext, useState } from "react";
import { Button, Card, Form, Modal, ProgressBar } from "react-bootstrap";
import { Cep } from "../../services/CepServices";
import { OsContext } from "../../context/OsContext";
import { ScreenContext } from "../../context/ScreenContext";
import InputMoeda from "../InputMoeda/InputModea";


export const ModalCriaOs = () => {
  const [show, setShow] = useState(false);
  const { screenSize } = useContext(ScreenContext);
  const [osValue, setOsValue] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 3;
  const { create, read } = useContext(OsContext);

  // Estado para manter os dados do formulário
  const [formData, setFormData] = useState({
    localeName: "",
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    estado: "",
    startDate: (() => {
      const today = new Date();
      return today.toISOString().split('T')[0];
    })(),
    osDescription: ""
  });

  const handleClose = () => {
    setShow(false);
    setCurrentStep(1);
    setFormData({
      localeName: "",
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
      startDate: (() => {
        const today = new Date();
        return today.toISOString().split('T')[0];
      })(),
      osDescription: ""
    });
    setOsValue(0);
  };

  const handleCep = async (e) => {
    if (e.length === 8) {
      const response = await Cep.get(e);
      if (!!response) {
        setFormData(prevData => ({
          ...prevData,
          cep: response.cep,
          logradouro: response.logradouro,
          complemento: response.complemento,
          bairro: response.bairro,
          cidade: response.localidade,
          estado: response.uf,
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      locale_name: formData.localeName,
      locale_street: formData.logradouro,
      locale_number: formData.numero,
      locale_complement: formData.complemento,
      locale_neighborhood: formData.bairro,
      os_value: osValue,
      locale_city: formData.cidade,
      locale_state: formData.estado,
      start_date: formData.startDate,
      os_status: "1",
      os_description: formData.osDescription,
    };

    const response = await create(payload);
    if (response) {
      handleClose();
      read()
    }
  };

  const handleNextStep = () => {
    if (currentStep < totalSteps) setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const stepIndicator = (
    <ProgressBar now={((currentStep - 1) / (totalSteps - 1)) * 100} className="mb-3" />
  );

  return (
    <>
      <Button
        className={`${screenSize.width < 1200 ? "p-3 w-100" : ""}`}
        onClick={() => setShow(true)}
      >
        Iniciar Atividades
      </Button>
      <Modal
        className="w-100"
        fullscreen={screenSize.width < 1200}
        size="xl"
        backdrop="static"
        show={show}
        onHide={handleClose}
      >
        <Modal.Body className="p-0">
          <Card className="card-one p-0">
            <form onSubmit={handleSubmit}>
              <Card.Body className="d-flex flex-column gap-3">
                <Card.Title>Cadastro de OS</Card.Title>
                <Card>
                  <Card.Body className="d-flex flex-column">
                    {stepIndicator}
                    {currentStep === 1 && <h5 className="text-center">Dados iniciais</h5>}
                    {currentStep === 2 && <h5 className="text-center">Dados de Endereço</h5>}
                    {currentStep === 3 && <h5 className="text-center">Dados Gerais</h5>}
                  </Card.Body>
                </Card>
                {currentStep === 1 && (
                  <Card>
                    <Card.Body>
                      <Form.Group>
                        <Form.Label>Título da Atividade</Form.Label>
                        <Form.Control
                          className={`${screenSize.width < 1100 ? "p-3" : ""}`}
                          name="locale_name"
                          id="locale_name"
                          type="text"
                          placeholder="Digite o título da atividade"
                          value={formData.localeName}
                          onChange={(e) => setFormData({ ...formData, localeName: e.target.value })}
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Data de Início</Form.Label>
                        <Form.Control
                          className={`${screenSize.width < 1100 ? "p-3" : ""}`}
                          type="date"
                          value={formData.startDate}
                          onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
                          id="start_date"
                          name="start_date"
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                )}
                {currentStep === 2 && (
                  <Card>
                    <Card.Body>
                      <Card.Title>Endereço</Card.Title>
                      <hr />
                      <Form.Group>
                        <div className="d-flex flex-column gap-3">
                          <Form.Control
                            className={`${screenSize.width < 1100 ? "p-3" : ""}`}
                            type="text"
                            name="cep"
                            id="cep"
                            value={formData.cep}
                            onChange={(e) => {
                              setFormData(prevData => ({ ...prevData, cep: e.target.value }));
                              handleCep(e.target.value);
                            }}
                            placeholder="CEP"
                          />
                          <Form.Control
                            className={`${screenSize.width < 1100 ? "p-3" : ""}`}
                            type="text"
                            name="logradouro"
                            id="logradouro"
                            value={formData.logradouro}
                            onChange={(e) => setFormData({ ...formData, logradouro: e.target.value })}
                            placeholder="Logradouro"
                          />
                          <Form.Control
                            className={`${screenSize.width < 1100 ? "p-3" : ""}`}
                            type="text"
                            name="numero"
                            id="numero"
                            value={formData.numero}
                            onChange={(e) => setFormData({ ...formData, numero: e.target.value })}
                            placeholder="Número"
                          />
                          <Form.Control
                            className={`${screenSize.width < 1100 ? "p-3" : ""}`}
                            type="text"
                            name="complemento"
                            id="complemento"
                            value={formData.complemento}
                            onChange={(e) => setFormData({ ...formData, complemento: e.target.value })}
                            placeholder="Complemento"
                          />
                          <Form.Control
                            className={`${screenSize.width < 1100 ? "p-3" : ""}`}
                            type="text"
                            name="bairro"
                            id="bairro"
                            value={formData.bairro}
                            onChange={(e) => setFormData({ ...formData, bairro: e.target.value })}
                            placeholder="Bairro"
                          />
                          <Form.Control
                            className={`${screenSize.width < 1100 ? "p-3" : ""}`}
                            type="text"
                            name="cidade"
                            id="cidade"
                            value={formData.cidade}
                            onChange={(e) => setFormData({ ...formData, cidade: e.target.value })}
                            placeholder="Cidade"
                          />
                          <Form.Control
                            className={`${screenSize.width < 1100 ? "p-3" : ""}`}
                            type="text"
                            name="estado"
                            id="estado"
                            value={formData.estado}
                            onChange={(e) => setFormData({ ...formData, estado: e.target.value })}
                            placeholder="Estado"
                          />
                        </div>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                )}
                {currentStep === 3 && (
                  <Card>
                    <Card.Body>
                      <Card.Title>Dados Gerais</Card.Title>
                      <hr />
                      {/* <Form.Group>
                        <Form.Label>Valor da OS</Form.Label>
                        <InputMoeda
                          className={`${screenSize.width < 1100 ? "p-3" : ""}`}
                          type="text"
                          name="os_value"
                          id="os_value"
                          value={osValue}
                          onChange={(e) => setOsValue(e.target.value)}
                        />
                      </Form.Group> */}
                      <Form.Group>
                        <Form.Label>Descrição da OS</Form.Label>
                        <Form.Control
                          className={`${screenSize.width < 1100 ? "p-3" : ""}`}
                          as="textarea"
                          rows={3}
                          name="os_description"
                          id="os_description"
                          value={formData.osDescription}
                          onChange={(e) => setFormData({ ...formData, osDescription: e.target.value })}
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                )}
              </Card.Body>
              <Card.Footer className="d-flex flex-row gap-3">
                {currentStep !== 1 && (
                  <Button variant="secondary" onClick={handlePreviousStep}>Anterior</Button>
                )}
                {currentStep !== 3 && (
                  <Button onClick={handleNextStep}>Próximo</Button>
                )}
                <div className="d-flex flex-row w-100 justify-content-end gap-3">
                  <Button
                    className={`${screenSize.width < 1200 ? "p-3 w-50" : ""}`}
                    variant="secondary"
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>
                  <Button
                    className={`${screenSize.width < 1200 ? "p-3 w-50" : ""}`}
                    type="submit"
                  >
                    Salvar
                  </Button>
                </div>
              </Card.Footer>


            </form>
          </Card>
        </Modal.Body >
      </Modal >
    </>
  );
};
