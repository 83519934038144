import Api from "../../../endpoint";

export const createAuthSlice = (set) => {
  return {
    id: 0,
    roleId: 0,
    name: "",
    email: "",
    fantasyName: "",
    isLogged: false,
    company: "",
    profile_image: "",
    setUser: (user) => {
      try {
        set({
          name: user.name,
          email: user.email,
          id: user.id,
          fantasyName: user.fantasy_name,
          isLogged: true,
          company: user.company,
          profile_image: user.profile_image_path,
          roleId: user.role_id,
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}