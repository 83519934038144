import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { AuthProvider } from './context/AuthContext';
import { OsProvider } from './context/OsContext';
import LoadComponent from './components/LoadComponent/Index';
import { LoadProvider } from './context/LoadingContext';
import { ScreenContextProvider } from './context/ScreenContext';
import { UserProvider } from './context/UserContext';
import { DashboardProvider } from './context/DashboardContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ScreenContextProvider>
      <AuthProvider>
        <UserProvider>
          <OsProvider>
            <DashboardProvider>
              <App />
              <div className="main-backdrop"></div>
            </DashboardProvider>
          </OsProvider>
        </UserProvider>
      </AuthProvider>
    </ScreenContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
